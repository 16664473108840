import dynamic from 'next/dynamic';

const Default = dynamic(() => import('./default'));
const Checkout = dynamic(() => import('./checkout-footer'), { ssr: false });

type TFooter = {
  type: TPageConfig['footer'];
};

const Footer = ({ type }: TFooter) => {
  switch (type) {
    case 'NONE':
      return null;
    case 'CHECKOUT':
      return <Checkout />;
    case 'DEFAULT':
    default:
      return <Default />;
  }
};

export default Footer;
